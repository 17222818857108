/** @jsx jsx */
import { jsx } from 'theme-ui';

const Callout = (props) => (
  <aside
    {...props}
    sx={{
      display: 'flex',
      alignItems: 'center',
      minHeight: 320,
      bg: 'gray1',
      p: 4,
      borderRadius: '8px',
    }}
  />
);

export default Callout;

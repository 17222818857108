/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';

const MarkdownContent = ({ space, children, ...props }) => {
  if (typeof children === 'string') {
    return children
      .trim()
      .split('\n')
      .map((p, i) => (
        <Styled.p
          key={i}
          dangerouslySetInnerHTML={{ __html: p }}
          {...props}
          sx={
            space && {
              '& + &': {
                mt: space,
              },
            }
          }
        />
      ));
  }
  return null;
};

MarkdownContent.defaultProps = {
  space: 5,
};

export default MarkdownContent;

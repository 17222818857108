/** @jsx jsx */
import { jsx } from 'theme-ui';

const List = ({ variant, simple, slim, rounded, ...props }) => (
  <ul
    {...props}
    sx={{
      variant: `lists.${variant}`,
      '> li': {
        display: 'flex',
        ...(!simple && {
          ':before': {
            content: `''`,
            flexShrink: 0,
            ...(rounded
              ? {
                  height: '6px',
                  width: '6px',
                  mt: '0.75em',
                  mr: '16px',
                  borderRadius: '3px',
                }
              : {
                  height: '2px',
                  width: '16px',
                  mt: '0.8em',
                  mr: '16px',
                  borderRadius: '0 2px 2px 0',
                }),
          },
        }),
      },
      '> li + li': {
        mt: slim ? '10px' : '20px',
      },
    }}
  />
);

List.defaultProps = {
  variant: 'default',
};

export default List;

/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import createGet from '../utils/create-get';

import headerAboutSvg from '../img/headers/header-about.svg';

import aboutPrinciplesSvg from '../img/illustrations/about-principles.svg';

import Layout from '../components/Layout';
import Container from '../components/Container2';
import MarkdownContent from '../components/MarkdownContent';
import { Button } from '../components/Buttons';
import List from '../components/List';
import Callout from '../components/Callout';
import AspectRatioImg from '../components/AspectRatioImg';

const Mission = ({ get }) => (
  <Container sx={{ mt: 11 }}>
    <Callout>
      <div>
        <Styled.h2 sx={{ '&&': { textTransform: 'uppercase' } }}>
          {get('mission.title')}
        </Styled.h2>
        <MarkdownContent sx={{ mt: 7 }}>{get('mission.text')}</MarkdownContent>
      </div>
    </Callout>
  </Container>
);

const Values = ({ get }) => (
  <Container sx={{ mt: 14 }}>
    <Styled.h3>{get('values.title')}</Styled.h3>
    <List
      rounded
      sx={{
        display: 'grid',
        gridTemplateColumns: ['1fr', null, '1fr 1fr'],
        gridGap: '16px',
        '> li + li': {
          mt: 0,
        },
        mt: 7,
      }}
    >
      {get('values.list', []).map((item, i) => (
        <li key={i}>{item}</li>
      ))}
    </List>
  </Container>
);

const Principles = ({ get }) => (
  <Container sx={{ mt: 14 }}>
    <div
      sx={{
        display: 'grid',
        gridTemplateColumns: ['1fr', null, '1fr 1fr'],
        gridGap: 7,
      }}
    >
      <div sx={{ display: ['none', null, 'block'] }}>
        <AspectRatioImg
          src={aboutPrinciplesSvg}
          alt=""
          ratio={440 / 506}
          maxWidth={400}
        />
      </div>
      <div>
        <Styled.h2>{get('principles.title')}</Styled.h2>
        <List rounded sx={{ mt: 7 }}>
          {get('principles.list', []).map((item, i) => (
            <li key={i}>{item}</li>
          ))}
        </List>
        <Button
          to={get('principles.cta.path')}
          variant="secondary"
          sx={{
            maxWidth: [null, null, '280px'],
            mt: 9,
          }}
          outlined
        >
          {get('principles.cta.label')}
        </Button>
      </div>
    </div>
  </Container>
);

export const AboutPageTemplate = (props) => (
  <section>
    <Container>
      <AspectRatioImg src={headerAboutSvg} alt="" ratio={912 / 256} />
      <Styled.h1 sx={{ mt: 13 }}>{props.get('title')}</Styled.h1>
      <MarkdownContent sx={{ mt: 7 }}>{props.get('text')}</MarkdownContent>
    </Container>
    <Mission {...props} />
    <Values {...props} />
    <Principles {...props} />
  </section>
);

const AboutPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  return (
    <Layout
      page="about"
      seo={{
        title: frontmatter.pageTitle,
        description: frontmatter.description,
      }}
    >
      <AboutPageTemplate get={createGet(frontmatter)} />
    </Layout>
  );
};

AboutPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default AboutPage;

export const pageQuery = graphql`
  query AboutPageQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        pageTitle
        description
        title
        text
        mission {
          title
          text
        }
        values {
          title
          list
        }
        principles {
          title
          list
          cta {
            label
            path
          }
        }
      }
    }
  }
`;

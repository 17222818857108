/** @jsx jsx */
import { jsx, AspectRatio } from 'theme-ui';

const AspectRatioImg = ({ ratio, width, maxWidth, centered, ...props }) => {
  const children = (
    <AspectRatio ratio={ratio}>
      {/* eslint-disable-next-line jsx-a11y/alt-text */}
      <img {...props} sx={{ width: '100%' }} />
    </AspectRatio>
  );

  return width || maxWidth ? (
    <div
      sx={{
        width,
        maxWidth,
        m: centered && '0 auto',
      }}
    >
      {children}
    </div>
  ) : (
    children
  );
};

export default AspectRatioImg;
